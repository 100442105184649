import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";


import Datetime from "react-datetime";
import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from "react-select";
import IconImage from "components/Icon/icon.jsx";
const moment = require('moment');
const Config = require('config');



class POSInfo extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      total:0,
      paid_amount:0,
      error:false,
      loading: true,
      alert: null,
     items:[{"item":"","cost":"","quantity":""}],
     date:'',
     customerAccounts:[],
     actiondate:'',
     duedateError:null,
     totalError:null,
      newinvoice:true,
      refid:'',
      status:'',
  invid:null,
  transactions:[],
  BtcTxUrl:Config.BtcTxUrl,
  BchTxUrl:Config.BchTxUrl,
  EthTxUrl:Config.EthTxUrl,
  LtcTxUrl:Config.LtcTxUrl,
  DogeTxUrl:Config.DogeTxUrl,
  UsdtTxUrl:Config.UsdtTxUrl,
  MinInvoice:Config.MinInvoice,
  currency:'USD'
    };


 
  }

  componentWillMount(){
this.setState({invid:this.props.match.params.invid,newinvoice:false}, this.getpos(this.props.match.params.invid),this.getpostransactions(this.props.match.params.invid))


  }



getpostransactions(invid){


  var user=JSON.parse(localStorage.getItem('user'));

  const requestOptions = {
    method: 'get',
    headers: { 'Content-Type': 'application/json','access-token': user.token,'mid':user.data.merchant_id}
 
};



return fetch(Config.API+`/api/pos/transactions/`+invid,requestOptions)
  .then( this.handleResponse)
  .then( transactiondata => this.setState({transactions:transactiondata.data, loading: false}))};






  getpos(invid){

    var user=JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      headers: { 'Content-Type': 'application/json' ,'access-token': user.token,'mid':user.data.merchant_id },
    };

    fetch(Config.API+'/api/pos/'+invid,requestOptions)
    .then( this.handleResponse)
     .then( invoice=> this.setState({
  
      paid_amount:invoice.data.paid_amount,
      total:invoice.data.total_amount,
        refid:invoice.data.ref_id,
       date:invoice.data.date,
        status:invoice.data.payment_status,
        currency:invoice.data.currency,
      invid_id:this.props.match.params.invid, loading: false}));

     
  
    }





  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
          //console.log(data.status)
          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'
          }
        

        }
       

        return data;
    });
}





   render() {
  const { isSubmitted,currency,BtcTxUrl,BchTxUrl,EthTxUrl,LtcTxUrl,DogeTxUrl,UsdtTxUrl,email,newinvoice,total,duedate,refid,status,paid_amount} = this.state; 
    


    var CurrencyOptions = [{ value: 'USD', label: 'USD'},{ value: 'CAD', label: 'CAD'},{ value: 'EUR', label: 'EUR'},{ value: 'GBP', label: 'GBP'}];
  
        

    
   
if (this.state.loading) return <Loader />
      return (
      <div className="main-content">
                      
        <Grid fluid>
   
          <Row>
      <Col lg={12}>  <h3>POS Transaction</h3> </Col>
            <Breadcrumb>
            <Breadcrumb.Item href="/#/billing">Billing</Breadcrumb.Item>
            <Breadcrumb.Item  href="/#/billing/pos">POS</Breadcrumb.Item>
            <Breadcrumb.Item active>POS Transaction Details</Breadcrumb.Item>
          </Breadcrumb>
                    <Col md={12}> 
          
                    <div>
           <Col sm={8}><h5>Transaction ID: <strong className="block">{refid}</strong><br/>Status: <strong className="block">{status}</strong></h5></Col>
           <Col sm={4}><table><tbody><tr><td>Billed amount:</td><td><strong>{currency==='USD' || currency==='CAD' ? '$'
: currency=='EUR'
? '€'
:currency=='GBP'
? '£'
: ''}{Number(total).toFixed(2)} {currency}</strong></td></tr><tr><td>Paid:</td><td><strong>{currency==='USD' || currency==='CAD' ? '$'
: currency=='EUR'
? '€'
:currency=='GBP'
? '£'
: ''}{Number(paid_amount).toFixed(2)} {currency}</strong></td></tr>
<tr><td>Amount due:</td><td><strong>{currency==='USD' || currency==='CAD' ? '$'
: currency=='EUR'
? '€'
:currency=='GBP'
? '£'
: ''}{Number(total - paid_amount).toFixed(2)} {currency}</strong></td></tr></tbody></table>
<br/>
<br/></Col>
                    </div>
                 </Col>
           
                  </Row>
          

                  <Row><Col sm={12}>
                  <div className="card">
                    <div className="header"><div className="title">Transaction Payments</div></div>
                    <div className="content">

                <table><thead><tr><td>Date</td><td>Status</td><td>Price ({this.state.currency})</td><td>Coin</td><td>Amount</td><td>Paid ({this.state.currency})</td><td>Tx Id</td></tr></thead><tbody>{ this.state.transactions.map((transaction, i) => <tr key={i}><td>{moment(transaction.date_time).format("MM/DD/YYYY H:m:s")}</td><td>{transaction.status}</td><td>{transaction.price} </td><td><IconImage  coin={transaction.coin}/>{transaction.coin}</td><td>{transaction.payment_amount}</td><td>{Number(transaction.total_payment).toFixed(2)}</td><td><a href={transaction.coin=='BTC'?BtcTxUrl+''+transaction.txid: transaction.coin=='BCH'?BchTxUrl+''+transaction.txid:transaction.coin=='ETH'?EthTxUrl+''+transaction.txid:transaction.coin=='LTC'?LtcTxUrl+''+transaction.txid:transaction.coin=='DOGE'?DogeTxUrl+''+transaction.txid:transaction.coin=='USDT'?UsdtTxUrl+''+transaction.txid:''}  target="_blank">View transaction</a></td></tr>)}</tbody></table>
                  
</div>
                  </div>
                  </Col></Row>

              
               
        </Grid>
      </div>
    );
  }
}

export default POSInfo ;

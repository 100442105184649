import React, { Component } from "react";
import { Grid, Col, Row} from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import moment from 'moment';
import PaymentsCard from "components/Card/PaymentsCard.jsx";
import Loading from "components/Loader/loading.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Loader from "components/Loader/loader.jsx";
import IconImage from "components/Icon/icon.jsx";
const Config = require('config');


class Payments extends Component {




    constructor(props){
        super(props);

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
                this.state = {
                  loading: false,
                  downloadbtn:false,
                paymentsdatalist:[],
                owed:"",
                paid:"",
                owedcad:"",
                paidcad:"",
                owedeur:"",
                paideur:"",
                owedgbp:"",
                paidgbp:"",
                downloading:false,
                  mid:"",
                  kyc:false,
                  from: undefined,
                  to: formatDate(new Date(),'YYYY-MM-DD')
                }
               
              //  console.log('this.props', this.props)
            }
               
            
            showFromMonth() {
              const { from, to } = this.state;
              if (!from) {
                return;
              }
            if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
            }
            }
          
            handleFromChange(from) {
              // Change the from date and focus the "to" input field
              this.setState({ from });
   
            }
          
            handleToChange(to) {
              this.setState({ to }, this.showFromMonth);
            }

            
componentWillMount(){
//this.getPaymentsTotals();
 
    var user=JSON.parse(localStorage.getItem('user'));
          
    if (user.data.kyc=='Verified'){this.setState({kyc:true})}else{
this.setState({kyc:false})}

  }
  








    getBalances(){
      this.setState({loading:true})
    var user=JSON.parse(localStorage.getItem('user'));
    var mid=user.data.merchant_id;
    var token=user.token;


    var date_time=this.state.to;
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'  ,'access-token': token,"mid":mid},
      body:JSON.stringify({date_time})
   };
      


    fetch(Config.API+'/api/balances/historical',requestOptions)
    .then( this.handleResponse)
    .then( withdrawdata => this.setState({ paymentsdatalist:withdrawdata.data, downloadbtn:true,mid:mid,loading:false}))
   
 
  }



  export(){ 


    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      filename:'Balances',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: false,
      title: '',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: ['Date','Coin','Balance','$ Balance']
    };
   
  const csvExporter = new ExportToCsv(options);


csvExporter.generateCsv(this.state.paymentsdatalist)

    


}

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}


  

  render() {
    const {downloading,to,downloadbtn} = this.state;
    const modifiers = { end: to };
    const FORMAT = 'YYYY-MM-DD';

    if(this.state.paid==null){this.setState({ paid:0})};
    if(this.state.paidcad==null){this.setState({ paidcad:0})};
    if(this.state.paideur==null){this.setState({ paideur:0})};
    if(this.state.paidgbp==null){this.setState({ paidgbp:0})};

    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
          
        <Grid fluid>
          <Row>

          <Col lg={12}> {this.state.kyc!=true?<Card 
      warning="true"
                  content={
                  <Row >
                    <Col  className="text-center">
                    
                    <i className="fas fa-exclamation-triangle"></i> Please <a  href={Config.VerifyUrl} target="_blank">Verify</a> your account. <a href={Config.VerifyUrl} target="_blank">Verify Now</a>
    
              
                   
                    </Col>
                  
                  </Row>
                }
              />:<div></div>}</Col>
          <Col lg={12}>  <h3>Historical Account Balances</h3>
          
 
          </Col>
  
          <Col lg={12}>
       
       <div className="card">
   
          

       <div className="content">

       <div className="options"> 
 {downloadbtn
?<Button  bsStyle="default" onClick={() => this.export()}>
            Export
             <span className="btn-label btn-label-right">
                <i className="fa fa-download " />
             </span>
           </Button>
           :''}
           <div className="DateSelector">   <div className="InputFromTo">

<DayPickerInput
      formatDate={formatDate}
      format={FORMAT}
      parseDate={parseDate}
      placeholder={formatDate(new Date(),'YYYY-MM-DD')}
      value={formatDate(new Date(),'YYYY-MM-DD')}
      dayPickerProps={{
      numberOfMonths:2
      }}
      onDayChange={this.handleToChange}

    />
    
    {' '}
{' '}
<span className="InputFromTo-to">

   <Button  bsStyle="default" onClick={() => this.getBalances()}>
             Go
            
            </Button>
</span>





</div></div>
           </div>


   
               <ReactTable
               
                 data={this.state.paymentsdatalist}
              
                 columns={[
                  {
                    Header: "Date",
                    accessor:"date",
                   
                  },
                  {
                    id:"coin",
                    Header: "Coin",
            


                    accessor: d =>  <div alt={d.coin}> <IconImage  coin={d.coin} />{d.coin}</div>
                   
                  

                  
                  },
                  {
                    Header: "Balance",
                    id: "balance",
                    accessor: d =>  <div >{d.balance} <br/><div className="small">(${d.usd})</div></div>,
                    Cell:"balance"
                  }
                 
                  
                  
                  
                   
                 
                  
                   
                   
                 ]}
                 noDataText= "No Balances Found"
                 filterable={false}
                 sortable={false}
                
                 showPaginationBottom={false}
                 className="-striped -highlight"
               />
             

            
       </div>
       </div></Col>




          </Row>
        </Grid>
      </div>









    );
  }
}

export default Payments;
import React, { Component } from "react";
import { Grid, Col, Row,FormControl } from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import moment from 'moment';
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";


import 'react-table-6/react-table.css'
import IconImage from "components/Icon/icon.jsx";
const Config = require('config');


class TransactionSearch extends Component {




    constructor(props){
        super(props);


        
                this.state = {
                  loading: false,
                transactionsdatalist:[],
                addressesdatalist:[],
                ref_id: ''
                  
                }
                this.handleChange = this.handleChange.bind(this);
                this.handleSubmit = this.handleSubmit.bind(this);
            
                this.handleValidate = this.handleValidate.bind(this);
              //  console.log('this.props', this.props)
            }
    
      
            
componentWillMount(){

   

  }
  
  gettransactions(){
    this.setState({ loading:true})
    var user=JSON.parse(localStorage.getItem('user'));
    var ref_id=this.state.ref_id;
    var token=user.token;
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'  ,'access-token': token,'mid':user.data.merchant_id},
      body:JSON.stringify({ref_id})
   };
      


    fetch(Config.API+'/api/transactionbyref/',requestOptions)
    .then( this.handleResponse)
    .then( transactiondata => this.setState({ transactionsdatalist:transactiondata.transdata,addressesdatalist:transactiondata.walletdata,loading:false},function(){

    
    }));
   

  }

 

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}




  

handleChange(e) {
  const { name, value } = e.target;
  this.setState({ [name]: value });

}




handleValidate(e) {
const { name, value } = e.target;
this.setState({ [name]: value });


}

handleSubmit(e) {
e.preventDefault();
const {ref_id} = this.state;
if(ref_id!=''){this.gettransactions();}

}


  render() {
    const { ref_id } = this.state;
 
    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
        <Grid fluid>
          <Row>
          <Col lg={12}>   <h3 className="header">Transaction Search by Ref/ Invoice ID</h3></Col>
<Col lg={12}>

          <div className="card">
      
             

          <div className="content">
         
          <div className="options"> 
          <FormControl
                            type="text"
                            name="ref_id"
                          value={ref_id}
                          placeholder="Ref/ Invoice ID"
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
          <Button  bsStyle="default" onClick={() => this.gettransactions()}>
                       Go
                      
                      </Button>
         </div>
         <h2 className="header">Wallet Addresses</h2>
         <ReactTable
                  
                  data={this.state.addressesdatalist}
                  filterable
                  columns={[
                    {
                      Header: "Coin",
                      id: "coin",
                      accessor: d =>  <div alt={d.coin}> <IconImage  coin={d.coin} />{d.coin}</div>
         
                    },
                    {
                      Header: "Address",
                      accessor: "address"
                    }
                   
                    ,
                    {
                      Header: "Tag",
                      accessor: "tag"
                    }
                   
                    ,
                    {
                      Header: "Ref/ Invoice ID",
                      accessor: "ref_id"
                    },
                    {
                      Header: "Alt5 ID",
                      accessor: "wid"
                    },
                   {
                      Header: "Date",
                      accessor:"date",
                     
                      Cell: props => {
                        return <Moment format="YYYY-MM-D H:mm:ss">{props.value}</Moment>;
                      },
                    }
                    
                    
                  ]}
                  noDataText= "No Addresses Found"
                  defaultPageSize={4}
            
                  showPaginationBottom={true}
                  className="-striped -highlight"
                />
                   <h2 className="header">Transaction Details</h2>
                  <ReactTable
                  
                    data={this.state.transactionsdatalist}
                    filterable
                    columns={[
                      {
                        Header: "Coin",
                        id: "coin",
                        accessor: d =>  <div alt={d.coin}> <IconImage  coin={d.coin} />{d.coin}</div>
             
                      }
                      , {
                        Header: "Date",
                        accessor:"date_time",
                       
                        Cell: props => {
                          return <Moment format="YYYY-MM-D H:mm:ss">{props.value}</Moment>;
                        },
                      },
                      {
                        Header: "Status",
                        accessor: "status"
                      }
                      ,
                      {
                        Header: "Ref/ Invoice ID",
                        accessor: "ref_id"
                      },
                      {
                        Header: "Wallet Address",
                        accessor: "address"
                      },
                      {
                        Header: "Transaction",
                        accessor: "txid"
                      },
                     
                      {
                        Header: "Payment Amount",
                        accessor: "payment_amount"
                      }
                      ,
                      {
                        id: "currency",
                        Header: "Currency",
                        accessor: d =>  <div alt={d.currency}> <IconImage  coin={d.currency} />{d.currency}</div>
             
                      }
                      ,{
                        Header: "Price",
                        id: "price",
                        accessor: d => {return d.price==null
                          ? 0
                        : d.price },
                        Cell: props => props.value.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                      }
                      
              
                     ,
                      {
                        id:"settled_currency",
                        Header: "Settled Currency",
                accessor: d =>  <div alt={d.settled_currency}> <IconImage  coin={d.settled_currency} />{d.settled_currency}</div>
                       
                      
  
                      
                      },

                      {
                        Header: "Settled Payment",
                        accessor: "settled_payment"
                      },
                      {
                        Header: "Settled Fee",
                        accessor: "settled_fee"
                      },
                      {
                        id:"total_payment",
                        Header: "Total Payment",
                     
                        accessor: d => {return d.total_payment==null
                          ? 0
                        : d.total_payment },
                        Cell: props => props.value.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                      },
                      {
                        Header: "Type",
                        accessor: "type"
                      },
                      {
                        Header: "Note",
                        accessor: "note"
                      }
                     
                      
                      
                    ]}
                    noDataText= "No Payments Found"
                    defaultPageSize={10}
                   
                    showPaginationBottom={true}
                    className="-striped -highlight"
                  />
                

               
          </div>
          </div></Col>
          </Row>
        </Grid>
      </div>









    );
  }
}

export default TransactionSearch;
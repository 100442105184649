import Dashboard from "views/Dashboard/Dashboard.jsx";
import Settings from "views/Settings/Settings.jsx";
import BankingInfo from "views/Settings/banking-info.jsx";
import AccountInfo from "views/Settings/account-info.jsx";
import PayNowWidget from "views/Settings/paynowwidget.jsx";
import Profile from "views/Profile/profile.jsx";
import Balances from "views/Balances/balances.jsx";
import BalancesHistorical from "views/Balances/balanceshistorical.jsx";
import Settlements from "views/Settlement/settlement.jsx";
import Addresses from "views/Addresses/addresses.jsx";
import Transactions from "views/Transactions/transactions.jsx";
import TransactionSearch from "views/Transactions/transaction-search.jsx";
import  ApiInfo from "views/Settings/api-info.jsx";
import  ApiKeys from "views/Settings/apis.jsx";
import  Billing from "views/Billing/billing.jsx";
import  Invoices from "views/Billing/invoices.jsx";
import  InvoiceInfo from "views/Billing/invoice-info.jsx";
import  Customers from "views/Billing/customers.jsx";
import  CustomerInfo from "views/Billing/customers-info.jsx";
import  POS from "views/Billing/pos.jsx";




import pagesRoutes from "./pages.jsx";


var dashboardRoutes = [
  {
    collapse: true,
  menuhide:false,
  state: "openBalances",
  name: "Account Balances",
  icon: "fal fa-balance-scale",
  
  views: [
    {
      menuhide:false,
      path: "/account_balances/current",
      name: "Current",
      icon: "fal fa-balance-scale",
      component: Balances
    },
    {
      menuhide:false,
      path: "/account_balances/historical",
      name: "Historical",
      icon: "fal fa-balance-scale",
      component: BalancesHistorical
    }]
  },{
 
    path: "/settlement_history",
    name: "Settlement History",
    icon: "fal fa-file-invoice-dollar",
    component: Settlements
   
  },{
    collapse: true,
  menuhide:false,
  state: "payments",
  name: "Payments",
  icon: "fal fa-hand-holding-usd",
  
  views: [
    {
      menuhide:false,
      path: "/payments_received",
    name: "Payments Received",
      icon: "fal fa-hand-holding-usd",
      component: Transactions
    },
    {
      menuhide:false,
      path: "/transaction search",
      name: "Transaction Search",
      icon: "fal fa-hand-holding-usd",
      component: TransactionSearch
    }]
  },{
 
    path: "/addresses",
    name: "Wallet Addresses",
    icon: "fal fa-wallet",
    component: Addresses
   
  },
  
      {
        menuhide:true,
        path: "/profile",
        name: "Profile",
        component: Profile
      },

      {
        menuhide:true,
        path: "/billing/invoices/:invid",
        name: "Invoice Info",
        icon: "fal fa-file-invoice-dollar",
        component: InvoiceInfo
       
      },
{
        menuhide:true,
        path: "/billing/customers/:clid",
        name: "Customer Info",
        icon: "fal fa-file-invoice-dollar",
        component: CustomerInfo
       
      },
      {
        menuhide:true,
        path: "/billing/invoices",
        name: "Invoices",
        icon: "fal fa-file-invoice-dollar",
        component: Invoices
       
      },


       {
        menuhide:true,
        path: "/billing/pos",
        name: "POS",
        icon: "far fa-credit-card-blank",
        component: POS
       
      },

      {
        menuhide:true,
        path: "/billing/customers",
        name: "Customers",
        icon: "fal fa-file-invoice-dollar",
        component: Customers
       
      },

      {
        menuhide:false,
        path: "/billing",
        name: "Billing",
        icon: "fal fa-file-invoice-dollar",
        component: Billing
       
      },
     
     
      
      
      
  { redirect: true, path: "/", pathTo: "/account_balances/current", name: "Home" }


];
export default dashboardRoutes;

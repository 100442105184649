let Config = {
API:'https://api.digitalpaydev.com:5000',
//API:'http://localhost:5000',
  MinInvoice:4,
  gtag:'AW-000000000',
  Company:'Digital Pay Dev',
  Website:'https://digitalpaydev.com',
  WidgetUrl:'https://digitalpaydev.com/widget/paydev.js?v4',
  WidgetName:'paydev',
  VerifyUrl:'https://digitalpaydev.com/merchant_verify/',
  wordpress:'https://wordpress.org/plugins/alt-5-pay-checkout-for-woocommerce/',
  POSUrl:'https://pos.digitalpaydev.com/'
};
module.exports = Config;

import { authHeader } from './auth-header.jsx';
const Config = require('config');
export const userService = {
    login,
    logout,
    forgot
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(Config.API+`/authenticate`, requestOptions)
      .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                user.authdata = window.btoa(username + ':' + password);
                
                localStorage.setItem('user', JSON.stringify(user));
                
            }
getAssets();
            return user;
        })
}



function getAssets() {
    var apps="true"
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ apps })
    };

    return fetch(Config.API+`/assets`, requestOptions)
      .then(handleResponse)
        .then(assets => {
            if (assets) {
                
                localStorage.setItem('assets', JSON.stringify(assets));
             
            }
            return assets;
        })
}





function forgot(username) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username})
    };

    return fetch(Config.API+`/api/users/forgot`, requestOptions)
      .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
              
            }

            return user;
        });
}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('assets');
}
function handleErrorResponse(response) {

    return Promise.reject('Error');
}
function handleResponse(response) {
  
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

         // const error = (data && data.message);
          return Promise.reject('Username or password is incorrect');
        }

        return data;
    });
}